:root {
    --color-1: #343a40;
    --color-1-RGB: 52,58,64;
    --color-2: #d8d8d8;
    --color-2-RGB: 216,216,216;
    --color-3: #007bff;
    --color-4: #ffffff;
    --color-5: #000;
    --color-5-RGB: 0,0,0;

    --headerHeight: 50px;
    --filterBoxHeight: 40px;

    --appBackGroundColor: var(--color-1);
    --appFontColor: var(--color-4);
    --appBorder: 0px solid var(--color-2);
    /*HEADER*/
    --brandHeaderTitleFontColor: var(--appFontColor);
    --brandHeaderTitleFontSize: 1.25rem;
    --righMenuFontSize: 1rem;
    --rightMenuFontColor: var(--brandHeaderTitleFontColor); /* or custom color --rightMenuFontColor: #0ee420*/
    --navbarTogglerIconColor: var(--color-3);
    --navbarMobileBackgroundColor: var(--color-1);
    --navbarMobileBorder: 2px solid var(--color-2);
    /*LEFT COLUMN*/
    --leftColumnHeadersFontSize: 1rem;
    --leftColumnContentFontSize: 0.75em;
    --leftColumnFontColor: var(--appFontColor);

    /*LEFT COLUMN -> NETWORK LIST BUTTON
    Possible classes: https://getbootstrap.com/docs/4.0/components/buttons/
    .sharp - make sharp corners
    .text-left|right|center - text align
    */
    --leftColumnNetworkListButtonClass: btn btn-listNetwork text-left btn-sm text-left;

    /*LEFT COLUMN -> NETWORK LIST BUTTON -> CUSTOM BUTTON */
    /*class: btn-listNetwork*/
    /*class: btn-outline-listNetwork*/
    --leftColumnNetworkListButtonBGColor: var(--color-5);/*only in btn-listNetwork*/
    --leftColumnNetworkListButtonBGColor-focus: rgba(var(--color-5-RGB), .5);
    --leftColumnNetworkListButtonBorderColor: rgba(var(--color-5-RGB), .2);
    --leftColumnNetworkListButtonBorderColor-focus: rgba(var(--color-5-RGB), .2);
    --leftColumnNetworkListButtonFontColor: var(--appFontColor);
    --leftColumnNetworkListButtonFontColor-focus: var(--appFontColor);


    /*LEFT COLUMN -> NETWORK LIST CONTENT*/
    --leftColumnNetworkListContentCorners: round; /*sharp | round*/
    --leftColumnNetworkListContentBGColor: var(--color-4);
    --leftColumnNetworkListContentBoxShadow: var(--color-2) 0px 0px 0px 1px;
    --leftColumnNetworkListContentBorder: 0px;
    --leftColumnNetworkListContentFontColor: var(--color-5);
    --leftColumnNetworkListContentFontColor-focus: var(--leftColumnNetworkListContentFontColor);
    --leftColumnNetworkListContentFontColorBG-focus: var(--color-2);
    --leftColumnNetworkListContentBootomLine: 1px solid rgba(var(--color-2-RGB),1);

    /*LEFT COLUMN -> DOWNLOAD ICON*/
    --downloadIconColor: white;

    /*RIGHT COLUMN -> NODES ACCORDION*/
    --rightColumnAccordionFontColor: var(--appFontColor);
    --rightColumnAccordionHeaderFontColor: var(--color-4);
    --rightColumnAccordionHeaderFontColor-focus: var(--color-2);
    --rightColumnAccordionHeaderBGColor: var(--color-5);
    --rightColumnAccordionNodeNameBGColor: rgba(var(--color-5-RGB), .4);

    --rightColumnAccordionExpandButton-focus: var(--color-2);
    --rightColumnAccordionLinksColor-focus: var(--color-2);

    --rightColumnAccordionCardBGColor: var(--color-1);
    --rightColumnAccordionCardBootomLine: 1px solid rgba(var(--color-2-RGB),.2);

    --rightColumnAccordionNodeOutcomeHoverBG: var(--rightColumnAccordionNodeNameBGColor);

    /*RIGHT COLUMN -> NODES ACCORDION BAR CHARTS*/
    --rightColumnAccordionCardBChartsBGColor: var(--color-4);
    --rightColumnAccordionCardBChartsProgressColor: var(--color-3);

    /*RIGHT COLUMN -> NODES ACCORDION HISTOGRAMS*/
    --rightColumnAccordionHistogramGridColor: var(--color-2);
    --rightColumnAccordionHistogramBarsColor: var(--color-3);

    /*RIGHT COLUMN -> NODES ACCORDION AREA CHARTS*/
    --rightColumnAccordionAreaChartGridColor: var(--color-2);

    /*MODALS -> HELLO MODAL, LICENSE MODAL, SET EVIDENCE MODAL IN HYBRID AND DYNAMIC*/
    --modalBGColor: var(--color-4);
    --modalBorder: var(--color-2) solid 1px;/*1px solid rgba(var(--color-2-RGB);,.9)*/
    --modalFontColor: var(--color-5);
    --modalButtonsClass: btn btn-secondary btn-sm; /* Only in SET EVIDENCE MODAL IN HYBRID and DYNAMIC. Possible classes: https://getbootstrap.com/docs/4.0/components/buttons/ */
    --modalOrderNumberBGColor: var(--color-2);/*Only in SET EVIDENCE MODAL IN DYNAMIC.*/
    --modalOrderNumberFontColor: var(--color-5);/*Only in SET EVIDENCE MODAL IN DYNAMIC.*/
    --modalOrderNumberBorder: 1px solid rgba(var(--color-5-RGB), .3);/*Only in SET EVIDENCE MODAL IN DYNAMIC.*/
    --modalInputEvidenceHeaderBGColor: var(--color-4);/*Only in SET EVIDENCE MODAL IN HYBRID.*/
    --modalInputEvidenceHeaderFontColor: var(--color-5);/*Only in SET EVIDENCE MODAL IN HYBRID.*/

    /*NODE FILTER*/
    --filterInputBGColor: var(--color-4);
    --filterInputBGColor-focus:var(--color-4);
    --filterIconBGColor: var(--color-5);
    --filterIconBGColor-focus: rgba(var(--color-5-RGB), .3);
    --filterIconColor: var(--color-4);

    /*TOOLTIPS*/
    --tooltipBGColor: var(--color-4);
    --tooltipBorder: 1px solid var(--color-5);
    --tooltipFontColor: var(--color-5);
    /*TOOLTIPS -> BAR CHART*/
    --tooltipBChartsBGColor: var(--color-2);
    --tooltipBChartsProgressColor: var(--color-3);
    /*TOOLTIPS -> HISTOGRAM*/
    --tooltipHistogramGridColor: var(--color-5);
    --tooltipHistogramBarsColor: var(--color-3);

    /*ZOOM MENU*/
    --zoomMenuBGColor: var(--color-4);
    --zoomMenuFontColor: var(--color-5);

    /*HIDDEN BUTTONS*/
    --hiddenButtonsColor: var(--color-1);

    /*BREADCRUMB*/
    --breadcrumbFontColor: var(--color-1);
    --breadcrumbFontColor-hover: var(--color-5);
    --breadcrumbFontColorActive: rgba(var(--color-1-RGB),.5);

    /*CASES MODAL*/
    --tableFontSize: 0.9em;
    /*
    Possible classes: thead-light, thead-dark
    */
    --tableHeadColorClass: thead-dark;
    /*
    Possible classes:
        table-bordered
        table-hover
        table-striped
        table-dark
        table-sm
        table-borderless
    */
    --tableClass: table-hover table-sm table-bordered;

    /*axis histogram color in card on dashboard*/
    --histogramGridColorInCard: var(--color-5);

    --phaseSpacePathColor: #D0D0D0;
    --phaseSpaceRectColor: #00C000;
    --phaseSpaceArrowColor: #D0D0D0;
}
