/*variables from variables.css*/
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
/*This just stops me getting horizontal scrolling if anything overflows the width*/

body {
    overflow-x: hidden;
}

/*Just removing default browser padding/margin*/

html, body {
    padding: 0;
    margin: 0;
}

.networkListItem {
    padding: 0.2rem 1.05rem !important;
}

.networkListItem.active {
    background-color: #343a40 !important;
    border-color: #343a40 !important;
}
.networkListItem:hover{
    background-color: #e6e6e6;
}

.customCard {
    max-width: none !important;
    margin-top: 0;
}

/*This is our main wrapping element, it's made 100vh high to ensure it is always the correct size and then moved into place and padded with negative margin and padding*/
#content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 100vh;
    margin-top: calc(0px - var(--headerHeight));
    padding-top: var(--headerHeight);
    position: relative;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: overflow;
}
/*All the scrollable sections should overflow and be whatever height they need to be. As they are flex-items (due to being inside a flex container) they could be made to stretch full height at all times if needed.
WebKit inertia scrolling is being added here for any present/future devices that are able to make use of it.
*/
#rightColumn,
#leftColumn,
#svgcontent {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    padding-bottom: 1rem;
}
#filterBox {
    padding-left: 0.425rem;
    padding-right: 0.425rem;
    height: var(--filterBoxHeight);
}
#rightColumn {
    padding-right: 0px;
    padding-left: 0px;
    border-left: var(--appBorder);
}
#nodesAccordion {
    padding-right: 0;
    padding-left: 0px;
    height: calc(100vh - var(--filterBoxHeight) - var(--headerHeight));
    color: var(--rightColumnAccordionFontColor);
}
#leftColumn {
    padding-right: 0px;
    padding-left: 0px;
    border-right: var(--appBorder);
    /*background-color: #343A40;*/
}
#svgcontent{
    padding-right: 0;
    padding-left: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.borderNone{
    border: 0;
}
.headerTop{
    background-color: rgba(0, 0, 0, 0.2) !important;
}
.appBGcolor {
    background-color: var(--appBackGroundColor);
}
.leftColumnHFontSize {
    font-size: var(--leftColumnHeadersFontSize);
    color: var(--leftColumnFontColor);
    margin-bottom: .25rem;
}
.leftColumnFont {
    font-size: var(--leftColumnContentFontSize);
    color: var(--leftColumnFontColor);
}
.appInfoStatus {
    color: var(--rightMenuFontColor);
}

div.tooltip {
    position: absolute;
    text-align: left;
    /*
    width: 60px;
    height: 28px;
    */
    padding: 0.25rem;
    /*padding-bottom: 0;*/
    font-size: 12px;
    background: var(--tooltipBGColor);
    border: var(--tooltipBorder);
    border-radius: 2px;
    pointer-events: none;
    min-width: 50px;
    color: var(--tooltipFontColor);
}
titleTooltip {
    font-size: 16px;
    color: var(--tooltipFontColor);
}
#tooltipValue .bar {
    fill: var(--tooltipHistogramBarsColor);
}
#tooltipValue line{
    stroke: var(--tooltipHistogramGridColor);
}
#tooltipValue path:not(.area, .histogramLine){
    stroke: var(--tooltipHistogramGridColor);
}
#tooltipValue text{
    fill: var(--tooltipHistogramGridColor);
}

@media (max-width: 624px) {
    #rightColumn {
        height: 100%;
    }
}
@media (min-width: 992px) {
    .my-dropdown-menu {
        width: 12em;
    }
}

#topMenu{
    padding-right: 0px;
    padding-left: 0px;
}

#appInfo{
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 10px;
}
/*Hide colums*/
.openbtn {
    position:absolute;
    top: 50%;
    padding:1em;
    padding: 0px;
    color: #343a40;
    display: none;
}
#openBtnRight {
    right:0;
    margin-right: 1px;
}
#openBtnLeft {
    left:0;
    margin-left: 1px;
}
.positionable{
    position: absolute;
    display: none;
    z-index: 1;/*lower than zoom dropdown list*/
}
/*Load icon*/
#waiting{
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    z-index: 1000;
}
/*Downloading icon*/
#download{
    color: var(--downloadIconColor);
}
#download:hover{
    text-decoration: none;
}
.clickToCopyAction {
    cursor: pointer;
}
.my-dropdown-menu{
    min-width: 0rem;
    max-height: calc(100vh - var(--headerHeight));
    overflow: auto;
    cursor: pointer;
}
#networkList{
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0;
    max-width: 100%;
}
.dropdown-item{
    white-space: normal;
    font-size: .8rem;
    width: auto;
}
.my-dropdown-item{
    padding: .05rem .9rem;
    font-size: .8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.custom-select{
    max-width: 70%;
}
.rowWithoutMargin{
    margin-right: 0;
    margin-left: 0;
    margin-top: 2vh;
}
.customProgress {
    height: 0.5rem;
    background-color: var(--rightColumnAccordionCardBChartsBGColor);
}
.customProgress div {
    background-color: var(--rightColumnAccordionCardBChartsProgressColor);
}
progresstitle{
    font-size: 0.75rem;
}
.myCard{
    border-radius: 0;
    border: 0;
    background-color: var(--rightColumnAccordionCardBGColor);
}
/*node search [right column]*/
.myCardSearch{
    border-radius: 0;
    border: 0;
    color: #fff;
    background-color: #000 !important;
}
.allCards .expandAll svg:hover {
    color: var(--rightColumnAccordionHeaderFontColor-focus);
    text-decoration: none;
    cursor: pointer;
}
.filterForm{
    padding: 0;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}
.filterForm .btn {
    color: #fff;
}
.blueButtonGroup button {
    background-color: var(--filterIconBGColor);
}

.blueButtonGroup button:hover {
    background-color: var(--filterIconBGColor-focus);
}
.blueButtonGroup button:focus,
.blueButtonGroup button.focus {
    box-shadow:0 0 0 .0rem rgba(0,90,90,0.5)
}
.blueButtonGroup button.disabled,
.blueButtonGroup button:disabled {
    background-color: var(--filterIconBGColor);
}
.blueButtonGroup button:not(:disabled):not(.disabled):active,
.blueButtonGroup button:not(:disabled):not(.disabled).active,
.show>.blueButtonGroup button.dropdown-toggle {
    background-color: var(----filterIconBGColor-focus);
}
.blueButtonGroup button:not(:disabled):not(.disabled):active:focus,
.blueButtonGroup button:not(:disabled):not(.disabled).active:focus,
.show>.blueButtonGroup button.dropdown-toggle:focus {
    box-shadow:0 0 0 .0rem rgba(0,90,90,0.5)
}
.blueButtonGroup svg {
    color: var(--filterIconColor);
}
.filterForm input {
    background-color: var(--filterInputBGColor);
}
.filterForm input:focus {
    background-color: var(--filterInputBGColor-focus);
    box-shadow: 0 0 0 0rem rgba(0,123,255,.25);
}
.mycard-header{
    padding: .0rem .425rem;
    background-color: var(--rightColumnAccordionNodeNameBGColor);
    position: relative;
    font-size: 0.9rem;
    border-bottom: 0px;
    border-top: var(--rightColumnAccordionCardBootomLine);
    border-radius: 0 0 0 0 !important;
}
.cardOutcome{
    color: var(--rightColumnAccordionFontColor);
    /*padding-right: calc(0.75em + 11px);*/ /*0.75em - font size 11px - scroll bar width*/
}
.cardOutcome:hover{
    color: var(--rightColumnAccordionLinksColor-focus);
    text-decoration: none;
}
.mycard-block{
    padding-left: 1.5rem;
    padding-right: 1rem;
}
.mycard-block .singleOutcomeBarChart:last-child {
    padding-bottom: .4rem;
}
.accordionTitle{
/*    padding: .4rem 1rem;
    padding-right: calc(2*1.25em + 11px);0.75em - font size 11px - scroll bar width*/
    padding-left: .425rem;
    margin-top: auto;
    margin-bottom: auto;

}
/*#targetAccordionTitle{
    display: none;
}*/
#targetsAccordion .myCard:last-child {
    padding-bottom: .2rem;
}
#otherAccordion .myCard:last-child {
    padding-bottom: .2rem;
}
.allCards{
/*    position: relative;
    padding: .0rem 0rem;*/
    border-top: var(--rightColumnAccordionCardBootomLine);
    background-color: var(--rightColumnAccordionHeaderBGColor);
    color: var(--rightColumnAccordionHeaderFontColor);
}
.expandAll{
/*    position: absolute;
    top: .3rem;
    right: .425rem;*/
    font-size: 1.25rem;
    padding-right: 11px;
}
.expandAll svg:hover {
    color: var(--rightColumnAccordionLinksColor-focus);
    text-decoration: none;
    cursor: pointer;
}
.appDescription{
    font-size: 0.9rem;
}
/*MODALS*/
.modalBayesbox {
    background-color: var(--modalBGColor);
    border: var(--modalBorder);
    color: var(--modalFontColor);
}
.modalBayesbox .modal-header {
    border-bottom: var(--modalBorder);
}
.modalBayesbox .modal-footer {
    border-top: var(--modalBorder);
}
.inputModalHeader {
    background-color: var(--modalInputEvidenceHeaderBGColor);
    color: var(--modalInputEvidenceHeaderFontColor);
    padding-bottom: 0;
    padding-left: .4rem;
    padding-right: .4rem;
    padding-top: .4rem;
}
.inputModalHeader .close,
.inputModalHeader .close:not(:disabled):not(.disabled):focus,
.inputModalHeader .close:not(:disabled):not(.disabled):hover {
    color: var(--modalInputEvidenceHeaderFontColor);
}
.inputModalHeader h5 {
    font-size: 1rem;
}
.orderNumberSytle {
    background-color: var(--modalOrderNumberBGColor);
    color: var(--modalOrderNumberFontColor);
    border: var(--modalOrderNumberBorder);
}
/*Multidimensional modal css*/
.multiDDiv{
    border: 1px black solid;
    position: absolute;
    z-index: 1200;
    border-radius: 4px;
    max-height: 100vh;
    max-width: 65vw;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f2f2f2;
    z-index: 1000;
    /*    left: 0;
        top: 0;*/
}
.multiDDivHeader{
    border-bottom: 1px solid #e9ecef;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
}
.multiDDivSpan{
    font-size: 1rem;
    padding-right: 1rem;
}
.multiDDivButton{
    position: absolute;
    top: 3px;
    right: 5px;
}
.multiDDivTable{
    overflow-x: auto;
    font-size: 0.65rem;
    padding: .4rem;
}
.multiDTable{
    margin: auto;
    width: 100%;
}
.multiDHeaderTable{
    text-align: center;
    vertical-align: middle !important;
    background-color: #e1e1e1;
}
.multiDDivTable th {
    border: 1px solid #969696;
}
.multiDDivTable td {
    border: 1px solid #969696;
}
/*The result is a matrix indexed */
.indexed{
    font-size: 0.75rem;
    cursor: pointer;
}
/*
Submenu
*/
.dropdown-accordion .panel-heading {
    padding: 0;
}
.dropdown-accordion .panel-heading a {
    display: block;
    padding: 10px 15px;
}
/*
Network list
*/
/*LEFT COLUMN -> NETWORK LIST BUTTON*/
.btn-listNetwork {
    color: var(--leftColumnNetworkListButtonFontColor);
    background-color: var(--leftColumnNetworkListButtonBGColor);
    border-color: var(--leftColumnNetworkListButtonBorderColor);
}

.btn-listNetwork:hover {
    color: var(--leftColumnNetworkListButtonFontColor-focus);
    background-color: var(--leftColumnNetworkListButtonBGColor-focus);
    border-color: var(--leftColumnNetworkListButtonBorderColor-focus);
}
.btn-listNetwork:focus,
.btn-listNetwork.focus {
    box-shadow: 0 0 0 0.2rem var(--leftColumnNetworkListButtonBorderColor-focus);
}
.btn-listNetwork.disabled,
.btn-listNetwork:disabled {
    color:var(--leftColumnNetworkListButtonFontColor);
    background-color:var(--leftColumnNetworkListButtonBGColor);
    border-color:var(--leftColumnNetworkListButtonBorderColor);
}
.btn-listNetwork:not(:disabled):not(.disabled):active,
.btn-listNetwork:not(:disabled):not(.disabled).active,
.show>.btn-listNetwork.dropdown-toggle {
    color: var(--leftColumnNetworkListButtonFontColor-focus);
    background-color: var(--leftColumnNetworkListButtonBGColor-focus);
    border-color: var(--leftColumnNetworkListButtonBorderColor-focus);
}
.btn-listNetwork:not(:disabled):not(.disabled):active:focus,
.btn-listNetwork:not(:disabled):not(.disabled).active:focus,
.show>.btn-listNetwork.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem var(--leftColumnNetworkListButtonBorderColor-focus);
}
.btn-outline-listNetwork {
  color: var(--leftColumnNetworkListButtonFontColor);
  background-color: transparent;
  background-image: none;
  border-color: var(--leftColumnNetworkListButtonBorderColor);
}

.btn-outline-listNetwork:hover {
  color: var(--leftColumnNetworkListButtonFontColor-focus);
  background-color: var(--leftColumnNetworkListButtonBGColor-focus);
  border-color: var(--leftColumnNetworkListButtonBorderColor-focus);
}

.btn-outline-listNetwork:focus, .btn-outline-listNetwork.focus {
  box-shadow: 0 0 0 0.2rem var(--leftColumnNetworkListButtonBorderColor-focus);
}

.btn-outline-listNetwork.disabled, .btn-outline-listNetwork:disabled {
  color: var(--leftColumnNetworkListButtonFontColor);
  background-color: transparent;
}

.btn-outline-listNetwork:not(:disabled):not(.disabled):active, .btn-outline-listNetwork:not(:disabled):not(.disabled).active,
.show > .btn-outline-listNetwork.dropdown-toggle {
  color: var(--leftColumnNetworkListButtonFontColor-focus);
  background-color: var(--leftColumnNetworkListButtonBGColor-focus);
  border-color: var(--leftColumnNetworkListButtonBGColor-focus);
}

.btn-outline-listNetwork:not(:disabled):not(.disabled):active:focus, .btn-outline-listNetwork:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-listNetwork.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--leftColumnNetworkListButtonBorderColor-focus);
}
.sharp {
  border-radius:0;
}
#dropdownMenuButton{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
/*LEFT COLUMN -> NETWORK LIST CONTENT*/
.dropdown-item {
    color: var(--leftColumnNetworkListContentFontColor);
}
.dropdown-item:focus, .dropdown-item:hover{
    background-color: var(--leftColumnNetworkListContentFontColorBG-focus);
    color: var(--leftColumnNetworkListContentFontColor-focus);
}
#dropdownNetworkList{
    width: 100%;
    padding-left: .425rem;
    padding-right: .425rem;
    padding-bottom: 0rem;
    padding-top: .3rem;
}
.dropdownContentNetworks {
    background-color: var(--leftColumnNetworkListContentBGColor);
    box-shadow: var(--leftColumnNetworkListContentBoxShadow);
    border: var(--leftColumnNetworkListContentBorder);
    width: 100%;
    padding: 0px;
    position: absolute;
    transform: translate3d(0px, 38px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
    font-size: 0.9rem;
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 1px;
}
.netListCard {
    border: 0px;
    background-color: transparent;
    border-radius: 0;
}
.netListCard-header {
    padding: 0;
    background-color: transparent;
    border-bottom: var(--leftColumnNetworkListContentBootomLine);
}
.netListBtn {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    padding: .1rem 1rem;
    color: var(--leftColumnNetworkListContentFontColor);
    font-weight: bold;
    cursor: pointer;
}
.netListBtn:hover {
    color: var(--leftColumnNetworkListContentFontColor);
}
/*
Histograms
*/
/*histograms in right column*/
.bar {
    fill: var(--rightColumnAccordionHistogramBarsColor);
}
.histogramLine {
    stroke: var(--rightColumnAccordionHistogramBarsColor);
}
.axisHistogramColor line{
    stroke: var(--rightColumnAccordionHistogramGridColor);
}
.axisHistogramColor path{
    stroke: var(--rightColumnAccordionHistogramGridColor);
}
.axisHistogramColor text{
    fill: var(--rightColumnAccordionHistogramGridColor);
}
.borderBar {
    fill: transparent;
    stroke: gray;
    stroke-width: .5px;
    stroke-opacity: .3;
}
/*Histogram grid*/
.grid line {
    stroke: var(--rightColumnAccordionHistogramGridColor);
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
}

.grid path {
    stroke-width: 0;
}
/*histograms in bar chart nodes*/
g[type=node] .bar {
    fill: green;
}
g[type=node] .errorBar {
    fill: #33cccc;
}
g[type=node] .histogramLine {
    stroke: #33cccc;
}
.errorDiamond {
    transform-box: fill-box;
    transform-origin: center;
    transform: rotate(45deg);
}
g[type=node] .grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
}
.axisBChartNode line{
    stroke: black;
}
.axisBChartNode path{
    stroke: black;
}
.axisBChartNode text{
    fill: black;
}
/*bar charts bar in tooltip*/
.progressTooltip {
    overflow: hidden;
    height: 7px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    background-color: var(--tooltipBChartsBGColor);
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-barTooltip {
    float: left;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    background-color: var(--tooltipBChartsProgressColor);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}
.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}
.pull-left{float:left}
.pull-right{float:right}
#outcomesTooltip:last-child{
    padding-bottom: 5px;
}
/*Prevent select text
https://stackoverflow.com/a/9314458
!!!This is an experimental technology!!!*/
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                          supported by Chrome and Opera */
}
/*RIGHT COLUMN -> NODES ACCORDION AREA CHARTS*/
.textselected{
    fill: var(--rightColumnAccordionAreaChartGridColor);
}
.axisAreaChartColor line{
    stroke: var(--rightColumnAccordionAreaChartGridColor);
}
.axisAreaChartColor path{
    stroke: var(--rightColumnAccordionAreaChartGridColor);
}
.axisAreaChartColor text{
    fill: var(--rightColumnAccordionAreaChartGridColor);
}
/*ZOOM MENU*/
#dropdownItemZoom {
    background-color: var(--zoomMenuBGColor);
    color: var(--zoomMenuFontColor);
}
/*HIDDEN BUTTONS*/
#closeBtnLeft,
#closeBtnRight {
    color: var(--hiddenButtonsColor);
}
/*BREADCRUMB*/
nav[aria-label=breadcrumb] a {
    color: var(--breadcrumbFontColor);
}
nav[aria-label=breadcrumb] a:hover {
    color: var(--breadcrumbFontColor-hover);
}
nav[aria-label=breadcrumb] .active {
    color: var(--breadcrumbFontColorActive);
}
/*Input dialog evidence*/
.dialogInputEv {
    border: 1px black solid;
    position: absolute;
    z-index: 1200;
    border-radius: 4px;
    max-height: 100vh;
    max-width: 65vw;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f2f2f2;
    z-index: 1000;
    /*    left: 0;
        top: 0;*/
}
/*virtual evidence modal*/
.virtualEvidenceLabel {
    width: calc(100%);
    display: inline-block;
}
.virtualEvidenceOption{
    margin-bottom: 0 !important;
}
.validateError {
    color: red;
    font-size: 0.7rem;
}
.probOfEvidenceValue{
    font-size: 1.4rem;
    overflow: auto;
}
/*prob evidence modal*/
.currentEvidence {
    background-color: #e9ecef;
    border-radius: .3rem;
    padding: 1rem 1rem;
    font-size: 1rem;
}
/*CASES MODAL*/
.casestable {
    font-size: var(--tableFontSize);
}
#casesTableModal .filter-control input,
#casesTableModal .filter-control select {
    /*from form-control-sm bootstrap*/
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}
.hideCards,
.expandCards {
    cursor: pointer;
}
.singleOutcomeBarChart:hover {
    background: linear-gradient(to right, transparent, var(--rightColumnAccordionNodeOutcomeHoverBG) 10% 90%, transparent);
}

.val {
    float: right;
}

.singleNodeOutcomes{
    width: 100%;
}

#selectGaugagesFirst {
    position: absolute;
    left: 50%;
    margin-left: -107px;
    top: 50%;
    margin-top: -24px;
    font-size: 2rem;
}

.animate-scale1-5 {
    transition: all .5s;
}
.animate-scale1-5:hover {
    transform: scale(1.5);
}
.btn-hover-black {
    color: black;
    opacity: 50%;
    background: white;
}
.btn-hover-black:hover {
    opacity: 100%
}
/*Remove the X from Internet Explorer and Chrome input type search */
/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }
/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.filterResultList {
    line-break: anywhere;
}

.foundElement:hover {
    background-color: #e8e8e8;
    cursor: pointer;
}

#phaseSpaceDropdownContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.phaseSpaceLine {
    stroke: var(--phaseSpacePathColor);
}
.phaseSpaceArrow {
    fill: var(--phaseSpaceArrowColor);
}
.phaseSpaceRect {
    fill: var(--phaseSpaceRectColor);
}
#phaseSpaceModal {
    padding: 10px 0px 35px 25px;
    overflow: visible !important;
    height: 500px;
    width: 700px;
    display: block;
    margin: 0 auto;
    transform-origin: -25px 0px;
}
