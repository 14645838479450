@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ns80w6#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ns80w6') format('truetype'),
    url('fonts/icomoon.woff?ns80w6') format('woff'),
    url('fonts/icomoon.svg?ns80w6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-pencil-alt-solid:before {
  content: "\e937";
}
.fa-binoculars-solid:before {
  content: "\e936";
}
.fa-filter-solid:before {
  content: "\e935";
}
.fa-layer-group-solid:before {
  content: "\e934";
}
.fa-thumbtack-solid:before {
  content: "\e933";
}
.fa-eye-solid:before {
  content: "\e931";
}
.fa-eye-slash-solid:before {
  content: "\e932";
}
.fa-key-solid:before {
  content: "\e930";
}
.fa-times-circle-solid:before {
  content: "\e92e";
}
.fa-plus-circle-solid:before {
  content: "\e92f";
}
.fa-link-solid:before {
  content: "\e92d";
}
.fa-info:before {
  content: "\e92b";
}
.fa-circle:before {
  content: "\e92c";
}
.fa-save-regular:before {
  content: "\e92a";
}
.fa-plus:before {
  content: "\e928";
}
.fa-minus:before {
  content: "\e929";
}
.fa-tachometer-alt-solid:before {
  content: "\e927";
}
.fa-sync:before {
  content: "\e926";
}
.fa-angle-down:before {
  content: "\e900";
}
.fa-angle-up:before {
  content: "\e901";
}
.fa-bars:before {
  content: "\e902";
}
.fa-bolt:before {
  content: "\e903";
}
.fa-briefcase:before {
  content: "\e904";
}
.fa-check-circle:before {
  content: "\e905";
}
.fa-chevron-circle-left:before {
  content: "\e906";
}
.fa-chevron-circle-right:before {
  content: "\e907";
}
.fa-cog:before {
  content: "\e908";
}
.fa-database:before {
  content: "\e909";
}
.fa-download:before {
  content: "\e90a";
}
.fa-edit:before {
  content: "\e90b";
}
.fa-exclamation-triangle:before {
  content: "\e90c";
}
.fa-facebook-f:before {
  content: "\e90d";
}
.fa-google:before {
  content: "\e90e";
}
.fa-info-circle:before {
  content: "\e90f";
}
.fa-internet-explorer:before {
  content: "\e910";
}
.fa-linkedin-in:before {
  content: "\e911";
}
.fa-lock:before {
  content: "\e912";
}
.fa-lock-open:before {
  content: "\e913";
}
.fa-play:before {
  content: "\e914";
}
.fa-power-off:before {
  content: "\e915";
}
.fa-project-diagram:before {
  content: "\e916";
}
.fa-question-circle:before {
  content: "\e917";
}
.fa-search:before {
  content: "\e918";
}
.fa-spinner:before {
  content: "\e919";
}
.fa-tag:before {
  content: "\e91a";
}
.fa-times:before {
  content: "\e91b";
}
.fa-toggle-off:before {
  content: "\e91c";
}
.fa-toggle-on:before {
  content: "\e91d";
}
.fa-trash:before {
  content: "\e91e";
}
.fa-user:before {
  content: "\e91f";
}
.fa-user-edit:before {
  content: "\e920";
}
.fa-user-plus:before {
  content: "\e921";
}
.fa-users-cog:before {
  content: "\e922";
}
.fa-user-secret:before {
  content: "\e923";
}
.fa-user-times:before {
  content: "\e924";
}
.fa-windows:before {
  content: "\e925";
}
