[data-simplebar] {
  overflow: auto;
}

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../scripts/simplebar-5.0.4/simplebar.css";
@import "~d3-context-menu/css/d3-context-menu.css";
@import "d3-context-menu-theme-bbox.css";
@import "variables.css";
@import "topMenu.css";
@import "customBootstrap.css";
@import "responsiveSVG.css";
@import "~hopscotch/dist/css/hopscotch.min.css";
@import "~bootstrap-table/dist/bootstrap-table.min.css";
@import "bootstrap-social-short.css";
@import "../scripts/icomoon-v1.0/fontawesome-free-5.11.2-web/css/svg-with-js.min.css";
@import "../scripts/icomoon-v1.0/fontawesome-free-5.11.2-web/css/fontawesome.css";
@import "../scripts/icomoon-v1.0/style.css";
