:root {
    --color-1: #343a40;
    --color-1-RGB: 52,58,64;
    --color-2: #d8d8d8;
    --color-2-RGB: 216,216,216;
    --color-3: #007bff;
    --color-4: #ffffff;
    --color-5: #000;
    --color-5-RGB: 0,0,0;
    
    --nodeMenuBGColor: var(--color-1);
    --nodeMenuBorderColor: rgba(var(--color-1-RGB), .5);
    --nodeMenuBGColor-hover: rgba(var(--color-2-RGB), .5);
    --nodeMenuFontColor: var(--color-4);
    --nodeMenuFontColor-hover: var(--color-4);
    /*
        Header
    */
    --nodeMenuHeaderBGColor: var(--color-5);
    --nodeMenuHeaderFontColor: var(--color-4);
    /*
        Disabled
    */
    --nodeMenuDisabledBGColor: var(--color-1);
    --nodeMenuDisabledFontColor: rgba(var(--color-2-RGB),.8);
    /*
        Divider
    */
    --nodeMenuDividerColor: rgba(var(--color-2-RGB),.8);
}
.d3-context-menu > ul > li > div.val {
	float: right;
}
/* Theming
------------ */

.d3-context-menu-theme-bayesbox {
	background-color: var(--nodeMenuBGColor);
	border-radius: 4px;

	font-family: Arial, sans-serif;
	font-size: 14px;
	border: 1px solid var(--nodeMenuBorderColor);
        
        color: var(--nodeMenuFontColor);
        
/*        max-height: 100vh;
        overflow: auto;*/
}

.d3-context-menu-theme-bayesbox ul {
	margin: 0px;
        border: 0.001px solid transparent;
}

.d3-context-menu-theme-bayesbox ul li {
	padding: 4px 16px;
}

.d3-context-menu-theme-bayesbox ul li:hover {
	background-color: var(--nodeMenuBGColor-hover);
	color: var(--nodeMenuFontColor-hover);
}

/*
	Header
*/

.d3-context-menu-theme-bayesbox ul li.is-header,
.d3-context-menu-theme-bayesbox ul li.is-header:hover {
	background-color: var(--nodeMenuHeaderBGColor);
	color: var(--nodeMenuHeaderFontColor);
	font-weight: bold;
}

/*
	Disabled
*/

.d3-context-menu-theme-bayesbox ul li.is-disabled,
.d3-context-menu-theme-bayesbox ul li.is-disabled:hover {
	background-color: var(--nodeMenuDisabledBGColor);
	color: var(--nodeMenuDisabledFontColor);
}

/*
	Divider
*/

.d3-context-menu-theme-bayesbox ul li.is-divider:hover {
	background-color: var(--nodeMenuDividerColor);
}

.d3-context-menu-theme-bayesbox ul hr {
	border: 0;
	height: 0;
	border-top: 0px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid var(--nodeMenuDividerColor);
        margin: 0;
}

/*
	Nested Menu
*/
.d3-context-menu-theme-bayesbox ul li.is-parent:after {
	border-left: 7px solid transparent;
	border-top: 7px solid var(--nodeMenuFontColor);
	content: "";
	height: 0;
	position: absolute;
	right: 8px;
	top: 35%;
	transform: rotate(45deg);
	width: 0;
}

.d3-context-menu-theme-bayesbox ul li.is-parent {
	padding-right: 20px;
	position: relative;
}

.d3-context-menu-theme-bayesbox ul.is-children {
	background-color: var(--nodeMenuBGColor);
	border: 1px solid var(--nodeMenuBorderColor);
	color: var(--nodeMenuFontColor);
	display: none;
	left: 100%;
	/*margin: -5px 0;*/
	padding: 4px 0;
	position: absolute;
        top: 0;
	width: 100%;
        border-radius: 4px;
        overflow: auto;
        max-height: 90vh;
        white-space: nowrap;
}

.d3-context-menu-theme-bayesbox ul.is-children li{
	overflow: hidden;
        text-overflow: ellipsis;
}

.d3-context-menu-theme-bayesbox li.is-parent:hover > ul.is-children {
	display: block;
}
