/*variables from variables.css*/
#header{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    height: var(--headerHeight);
    border-bottom: var(--appBorder);
}

#header nav{
    height: var(--headerHeight);
}
#togglerIcon {
    color: var(--navbarTogglerIconColor);
}
.navbar-brand{
    padding-top: 0;
    padding-bottom: 0;
}
.brandHeaderTitle {
    color: var(--brandHeaderTitleFontColor);
    font-size: var(--brandHeaderTitleFontSize);
}
.headerRight {
    font-size: var(--righMenuFontSize);
    color: var(--rightMenuFontColor) !important;
}
.headerRight:focus, 
.headerRight:hover {
    text-decoration: none;
}
#leftMenu strong {
    color: var(--rightMenuFontColor) !important;
}
.leftBorder{
    border-left: var(--appBorder);
    padding-left: 1em;
}
#leftMenu{
    font-weight: lighter;
}
#updateManual a.disabled {
    /* And disable the pointer events */
    pointer-events: none;
}
#updateManual a.disabled svg{
    /* Make the disabled links grayish*/
    color: gray;
}
/*Large devices (desktops, 992px and down)*/
@media (max-width: 991px) {
    .brandHeaderTitle {
        max-width: 75vw;
    }
    #header nav #nav-content{
        border-bottom: var(--navbarMobileBorder);
        border-left: var(--navbarMobileBorder);
        border-right: var(--navbarMobileBorder);
        background-color: var(--navbarMobileBackgroundColor);
        border-bottom-left-radius: .4rem;
        border-bottom-right-radius: .4rem;
        padding: 0 .4rem;
        overflow-y: auto;
        max-height: calc(100vh - var(--headerHeight) - 10px);
    }
}
